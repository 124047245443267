import {
  Collapse,
  Flex,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { HiChevronDown } from "react-icons/hi";
import { Link, NavLink } from "react-router-dom";
import { PrimaryButton } from "../../PrimaryButton";

export const MobileNavbar = ({ navItem, onClose }) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <Stack p={2} display={{ md: "none" }} bg={"#7572FF"} mt={4} rounded={"5"}>
        {navItem.map((item, i) => (
          <Stack key={i} spacing={4} onClick={item.children && onToggle}>
            <Flex
              py={1}
              pl={2}
              as={NavLink}
              to={item.href}
              align={"center"}
              transition={"all .25s ease-in-out"}
              borderRadius={5}
              _hover={{
                bg: "#5956E9",
              }}
              role={"group"}
              onClick={onClose}
              style={({ isActive, isPending }) => {
                return {
                  background: isActive ? "#5956E9" : "",
                };
              }}
            >
              <Text fontWeight={600} textColor={"white"}>
                {item.label}
              </Text>
              {item.children && (
                <Icon
                  as={HiChevronDown}
                  transition={"all .25s ease-in-out"}
                  transform={isOpen ? "rotate(180deg)" : ""}
                  w={6}
                  h={6}
                  ml={1}
                  _groupHover={{ color: "primary.700" }}
                />
              )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: "0" }}>
              <Stack mt={2} pl={4} align={"start"}>
                {item.children &&
                  item.children.map((child, i) => (
                    <Text
                      as={Link}
                      key={i}
                      href={child.href}
                      fontWeight={"semibold"}
                      _hover={{ color: "primary.700" }}
                      transition={"all .25s ease-in-out"}
                    >
                      {child.label}
                    </Text>
                  ))}
              </Stack>
            </Collapse>
          </Stack>
        ))}
        <Text to="/job-openings" as={Link} display={"block"} py={2}>
          <PrimaryButton
            customStyle={{
              borderRadius: "full",
              paddingY: { base: "15px", lg: "20px", xl: "20px" },
              bg: "white",
              color: "primary",
              boxShadow: "none",
              _hover: { bg: "white", color: "primary", boxShadow: "none" },
              _focus: { bg: "white", color: "primary", boxShadow: "none" },
            }}
            onClick={onClose}
          >
            Careers
          </PrimaryButton>
        </Text>
      </Stack>
    </>
  );
};
