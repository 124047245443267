import abpLogo1 from "../Assets/abp-logo-1.png";
import brand1 from "../Assets/blue-ribbon.png";
import brand8 from "../Assets/company_logo.png";
import brand3 from "../Assets/i2ifunding.png";
import brand6 from "../Assets/logo_ditto_insurance.png";
import brand7 from "../Assets/logo_noora_health.png";
import brand2 from "../Assets/razorpay.png";
import brand10 from "../Assets/progcap.png";

export const brands = [
  {
    src: brand6,
    alt: "Ditto Insurance",
    height: "45px"
  },
  {
    src: abpLogo1,
    alt: "ABP",
    height: "75px"
  },
  {
    src: brand1,
    alt: "Blue Ribbon",
    height: "65px"
  },
  {
    src: brand7,
    alt: "Noora Health",
    height: "35px"
  },
  {
    src: brand10,
    alt: "Progcap",
    height: "35px"
  }
];
// brand6,
// abpLogo1,
// brand1,
// brand8,
// brand9,
// brand3,
// brand7,
// brand10
// brand2,
// abpLogo2,
// abpLogo3,
// abpLogo4,
// abpLogo5,

