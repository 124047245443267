import { Box, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import antlerImage from "../../Assets/antler.png";
import deloitte from "../../Assets/deloitte-1.png";
import swiggyImage from "../../Assets/swiggy-1.png";
import samved from "../../Assets/samved.png";
import m2pImage from "../../Assets/m2p-logo-1.png";

const logos = [
  {
    src: m2pImage,
    alt: "M2P",
    height: "35px",
    width: "35px",
  },
  {
    src: swiggyImage,
    alt: "Swiggy",
    height: "35px",
    width: "35px",
  },
  {
    src: deloitte,
    alt: "Deloitte",
    height: "20px",
    width: "95px",
  },
];

export default function BrandBar() {
  return (
    <Flex
      justify={"center"}
      align={"center"}
      flexDir={{ base: "column", md: "column" }}
      pb={5}
      gap={{ base: 6, md: 10 }}
    >
      <VStack justify={"center"} gap={2}>
        <Text>We're backed by</Text>
        <Flex gap={8} justify={"center"} align={"center"}>
          <Box h={"25px"} w={"100px"}>
            <Image
              src={antlerImage}
              alt="Antler"
              h={"full"}
              w={"full"}
              objectFit={"contain"}
            />
          </Box>
          <Box h={"30px"} w={"75px"}>
            <Image
              src={samved}
              alt="Antler"
              h={"full"}
              w={"full"}
              objectFit={"contain"}
            />
          </Box>
        </Flex>
      </VStack>
      <VStack justify={"center"} gap={2}>
        <Text>& top execs at</Text>
        <HStack spacing={8}>
          {logos.map(({ src, alt, height, width }, index) => (
            <Box h={height} w={width} key={index}>
              <Image
                src={src}
                alt={`${alt}`}
                h={"full"}
                w={"full"}
                objectFit={"contain"}
              />
            </Box>
          ))}
        </HStack>
      </VStack>
    </Flex>
  );
}
