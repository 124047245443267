import { Box, Heading, Image } from "@chakra-ui/react";
import React from "react";
import { brands } from "../../logos/brandLogos";
import BrandCarousel from "./BrandCarousel/BrandCarousel";

export const TrustedBy = () => {
  return (
    <Box
      bg={"#F9F9F9"}
      pb={{ base: 0, md: 5 }}
      pt={6}
      mb={{ base: 4, md: 6, lg: 8 }}
    >
      <Box>
        <Heading
          fontSize={{ base: "2xl", md: "4xl" }}
          textAlign={"center"}
          mb={3}
        >
          Trusted by
        </Heading>
        <Box overflow={"hidden"} py={{ base: 6, md: 8 }}>
          <BrandCarousel>
            {brands?.map((item, i) => (
              <Box
                sx={{
                  height: item.height,
                }}
                verticalAlign={"middle"}
                key={i}
              >
                <Image
                  src={item.src}
                  alt={item.alt}
                  w={"full"}
                  h={"full"}
                  objectFit={"contain"}
                  filter={"grayscale(1)"}
                />
              </Box>
            ))}
          </BrandCarousel>
        </Box>
      </Box>
    </Box>
  );
};
