import {
  Box,
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";

import { HiChevronDown } from "react-icons/hi";
import { Link, NavLink } from "react-router-dom";
import { PrimaryButton } from "../../PrimaryButton";

export const DesktopNavbar = ({ navItem }) => {
  return (
    <>
      <Stack direction={"row"} spacing={4} alignItems={"center"}>
        {navItem.map((item, i) => (
          <Box key={i}>
            <Popover trigger={"hover"} placement={"bottom-start"}>
              <PopoverTrigger>
                <Text
                  p={2}
                  px={0}
                  pb={0}
                  mx={2}
                  as={NavLink}
                  to={item.href}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                    };
                  }}
                  textColor={"#000000"}
                  fontSize={["sm", "md", "lg", "lg", "lg", "xl"]}
                  borderWidth={2}
                  borderColor="transparent"
                  transition={"all 0.5s"}
                >
                  {item?.label}{" "}
                  {item?.children && (
                    <Icon
                      w={5}
                      h={5}
                      as={HiChevronDown}
                      verticalAlign={"middle"}
                    />
                  )}
                </Text>
              </PopoverTrigger>

              {item?.children && (
                <PopoverContent
                  borderWidth={3}
                  borderColor={"primary.700"}
                  boxShadow={"xl"}
                  p={4}
                  rounded={"xl"}
                  w={"fit-content"}
                >
                  <Stack>
                    {item?.children.map((child) => (
                      <Text
                        href={child.href}
                        role={"group"}
                        display={"block"}
                        p={2}
                        rounded={"md"}
                        _hover={{ bg: "transparent" }}
                      >
                        <Stack direction={"row"} align={"center"}>
                          <Box>
                            <Text
                              transition={"all .3s ease"}
                              _groupHover={{ color: "primary.700" }}
                              fontWeight={500}
                            >
                              {child?.label}
                            </Text>
                          </Box>
                        </Stack>
                      </Text>
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        ))}
        <Text to="/job-openings" as={Link} pl={5}>
          <PrimaryButton
            customStyle={{
              borderRadius: "full",
              paddingY: { base: "15px", lg: "16px", xl: "16px" },
              boxShadow: "none",
              fontWeight: "semibold",
            }}
          >
            Careers
          </PrimaryButton>
        </Text>
      </Stack>
    </>
  );
};
