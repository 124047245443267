import { Box } from "@chakra-ui/react";
import AnimatedNumber from "../../Components/AnimatedNumber/AnimatedNumber";
import BrandBar from "../../Components/Brands/BrandBar";
import { TrustedBy } from "../../Components/Brands/TrustedBy";
import Container from "../../Components/Container/Container";
import FinancialStep from "../../Components/FinancialStep/FinancialStep";
import FreePlan from "../../Components/FreePlan/FreePlan";
import HeroCarousel from "../../Components/HeroCarousel/HeroCarousel";
import KeepInTouch from "../../Components/KeepInTouch/KeepInTouch";
import Testimonials from "../../Components/Testimonials/Testimonials";
import WhatDo from "../../Components/WhatDo/WhatDo";
import useDynamicTitle from "../../Hooks/useDynamicTitle";
import FAQ from "../FAQ/FAQ";
import ForEmployees from "../ForEmployees/ForEmployees";

export default function Home() {
  useDynamicTitle("Turn unused leaves into cash!");
  return (
    <>
      <ForEmployees />
    </>
  );
}
