import { Box, Heading, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import i2iLogo from "../../Assets/bp_lender_8.png";
import { Link } from "react-router-dom";

export default function BusinessPartner() {
  return (
    <>
      <Box py={10} px={5}>
        <Heading textAlign={"center"} fontWeight={"normal"}>
          Our Lending Partners
        </Heading>
        <VStack mt={10}>
          <Image src={i2iLogo} h={10} w={10} />
          <Text fontSize={"lg"} fontWeight={500}>
            i2iFunding (RNVP Technology Pvt Ltd.)
          </Text>
          <Text color={"gray.500"}>Noida</Text>
          <Text fontWeight={500}>
            <Text
              as={Link}
              to={"https://www.i2ifunding.com/"}
              target="_blank"
              display={"inline-block"}
              color={"#4F52FF"}
              _hover={{ textDecoration: "underline" }}
            >
              Website
            </Text>{" "}
            ,{" "}
            <Text
              as={Link}
              to="https://www.i2ifunding.com/grievance-redressal-policy/"
              target="_blank"
              display={"inline-block"}
              color={"#4F52FF"}
              _hover={{ textDecoration: "underline" }}
            >
              Terms & Policy
            </Text>
          </Text>
          <Text color="gray.500" textAlign={"center"} fontSize={"16px"}>
            2iFunding's Redressal Officer Details:
            <br />
            Name - Tushita Rastogi
            <br />
            Address: i2iFunding, Ground Floor, A-217, <br />
            Sector 69, Noida, 201301
            <br />
            Email: investment@i2ifunding.com
            <br />
            Contact : +91-9717420307
          </Text>
        </VStack>
      </Box>
    </>
  );
}
