import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const TELEGRAM_BOT_API_URL = process.env.REACT_APP_TELEGRAM_BOT_API_URL;

const generateMessage = (data) => {
  return `Delete Account Request\n\n${
    data.email
      ? `Email: ${data.email}`
      : data.phone
      ? `Phone: ${data.phone}`
      : ""
  }`;
};

const DeleteAccount = () => {
  const [status, setStatus] = useState("idle");
  const [inputValue, setInputValue] = useState("");
  const toast = useToast();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("submitting");
    if (isValidInput(inputValue)) {
      const userData = getUserData(inputValue);
      const payload = {
        chat_id: "-908107678",
        text: generateMessage(userData),
      };
      try {
        const response = await axios.post(TELEGRAM_BOT_API_URL, payload);
        if (response.data.ok) {
          toast({
            title: "Thanks for submitting your request",
            description:
              "We will reach out you in next 48 hours for verification",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setInputValue("");
          navigate("/");
        }
        setStatus("success");
      } catch (error) {
        setStatus("error");
        console.error(error);
      }
    } else {
      setStatus("error");
      toast({
        title: "Input Invalid!",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return false;
    }
  };

  // Check validation
  const isValidInput = (input) => {
    const mobileRegex = /^\d{10}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return mobileRegex.test(input) || emailRegex.test(input);
  };

  // getting user data by condition
  const getUserData = (input) => {
    const mobileRegex = /^\d{10}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return mobileRegex.test(input)
      ? { phone: `+91${input}` }
      : emailRegex.test(input)
      ? { email: input }
      : {};
  };
  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        h={{ sm: "auto", md: "auto", lg: "100vh" }}
        p={5}
      >
        <Text
          fontSize={{
            base: "2xl",
            md: "3xl",
            lg: "3xl",
            "2xl": "4xl",
          }}
          color="primary"
          fontWeight="bold"
        >
          <Box
            borderWidth={"1px"}
            w={["auto", "500px"]}
            p={5}
            borderRadius={"5px"}
            borderColor={["primary", "gray.300"]}
          >
            <form onSubmit={handleSubmit}>
              <FormControl>
                <Text
                  color={"gray.600"}
                  fontSize={{ base: "sm", md: "sm", lg: "sm", "2xl": "lg" }}
                  fontWeight={"normal"}
                  mb={2}
                >
                  Registered email or mobile number
                </Text>
                <Input
                  _placeholder={{
                    color: "gray.400",
                  }}
                  color={"gray.600"}
                  borderColor={["primary", "gray.700"]}
                  bg={"transparent"}
                  focusBorderColor={["gray.200", "gray.700"]}
                  py={4}
                  id={"phone"}
                  type={"text"}
                  fontSize={{ base: "sm", md: "lg", lg: "lg", "2xl": "xl" }}
                  w={"100%"}
                  required
                  placeholder={"Your email or mobile number"}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </FormControl>
              <Button
                type="submit"
                size={"md"}
                bg="primary"
                mt={3}
                color={"white"}
                _hover={{ bg: "primary" }}
                isLoading={status === "submitting"}
                isDisabled={status === "submitting"}
              >
                Submit
              </Button>
            </form>
          </Box>
        </Text>
      </Flex>
    </>
  );
};

export default DeleteAccount;
